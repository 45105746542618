import NewDisk from '@/layouts/Stack/components/NewDisk';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
  },
  methods: {
    newDisk: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'newDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewDisk,
          closeOnBackdrop: false,
          props: {
            quota: this.quota,
            size: 5,
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.addNewDisk(instance)
                    .then(async data => {
                      // console.log(data);
                      this.newFetch();
                      that.$modals.close();
                      this.showResModal('Диск успешно создан.');

                      this.data = { disk: data.volume.id };
                      // console.log(this.data);
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewDisk(payload) {
      return this.$store.dispatch('moduleStack/createDisk', payload).catch(e => {
        this.showError(e);
      });
    },
  },
};
